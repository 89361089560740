import {addDays} from "date-fns/addDays";
import {addYears} from "date-fns/addYears";
import {getDayOfYear} from "date-fns/getDayOfYear";
import {setDayOfYear} from "date-fns/setDayOfYear";
import {differenceInDays} from "date-fns/differenceInDays";
import {isBefore} from "date-fns/isBefore";
import {startOfDay} from "date-fns/startOfDay";
import {format} from "date-fns/format";

import merge from 'lodash/merge';

const temperatureGradient = d3.interpolateRgbBasis([
  '#d52304',
  '#ff981e',
  '#fcd288',
  '#fae81e',
  '#69b799',
  '#094a54',
  '#21254d',
]);

const rainfallGradient = d3.interpolateRgbBasis([
  '#1882ff',
  '#49496c',
]);

const formatDate = date => format(date, "MM-dd");

const PERTH = {
  lat: -31.9522,
  lon: 115.8614
};

class WeatherData {
  constructor() {
    this._raw = {};
    this._data = null;
    this._ranges = null;
    this._results = null;
    this._lastUpdate = new Date(1960, 0, 1);
  }

  get lastUpdate() {
    return this._lastUpdate;
  }

  get results() {
    return this._results;
  }

  get ranges() {
    return this._ranges;
  }

  loadFromCache() {
    if (localStorage["rawCache"]) {
      this._raw = JSON.parse(localStorage["rawCache"]);

      Object.keys(this._raw).forEach(key => {
        const keyDate = new Date(this._raw[key].time);
        this._raw[key].time = keyDate;

        if (keyDate > this._lastUpdate) {
          this._lastUpdate = keyDate
        }
      });
    }
  }

  async loadData(sourceUrl) {
    console.warn("Load data", sourceUrl);
    const res = await fetch(sourceUrl);
    const rawData = await res.json();

    const parsedRaw = await parseRaw(rawData.daily);

    merge(this._raw, parsedRaw);
  }

  saveToCache() {
    localStorage["rawCache"] = JSON.stringify(this._raw);
  }

  parseResults() {
    const ranges = {};
    const results = {};
    ALL_KEYS.forEach(key => ranges[key] = {...minMaxObj});

    Object.keys(this._raw).forEach(fullDateKey => {
      const dayKey = fullDateKey.substring(5);

      if (!results[dayKey]) {
        results[dayKey] = {
          ranges: {},
        };

        ALL_KEYS.forEach(key => {
          results[dayKey][key] = [];
          results[dayKey].ranges[key] = {...minMaxObj};
        });
      }

      ALL_KEYS.forEach(key => {
        const currentVal = this._raw[fullDateKey][key];
        const rangeObj = results[dayKey].ranges[key];
        results[dayKey][key].push(currentVal);

        if (isNaN(rangeObj.maximum) || currentVal > rangeObj.maximum) {
          rangeObj.maximum = currentVal;

          if (isNaN(ranges[key].maximum) || currentVal > ranges[key].maximum) {
            ranges[key].maximum = currentVal;
          }
        }

        if (isNaN(rangeObj.minimum) || currentVal < rangeObj.minimum) {
          rangeObj.minimum = currentVal;

          if (isNaN(ranges[key].minimum) || currentVal < ranges[key].minimum) {
            ranges[key].minimum = currentVal;
          }
        }
      })
    });

    this._ranges = ranges;
    this._results = results;
  }
}

async function fetchData() {
  const weatherData = new WeatherData();
  weatherData.loadFromCache();

  const daysSinceUpdate = differenceInDays(new Date(), weatherData.lastUpdate);

  let forecastDaysRequired = 1;

  console.debug("Days since update:", daysSinceUpdate, "forecast days required:", forecastDaysRequired);

  try {
    if (daysSinceUpdate > 7) {
      // Download data since daysSinceUpdate to yesterday
      const startDate = format(weatherData.lastUpdate, "yyyy-MM-dd");
      const yesterday = format(addDays(new Date(), -1), "yyyy-MM-dd");

      await weatherData.loadData(`https://archive-api.open-meteo.com/v1/archive?latitude=${PERTH.lat}&longitude=${PERTH.lon}&start_date=${startDate}&end_date=${yesterday}&daily=temperature_2m_max,temperature_2m_min,rain_sum&wind_speed_unit=kn&timezone=Asia%2FSingapore`);
    } else {
      // < 7 days missing, just fetch forecast
      forecastDaysRequired = daysSinceUpdate;
    }

    if (isBefore(weatherData.lastUpdate, startOfDay(new Date()))) {
      console.debug("Last update", weatherData.lastUpdate, "downloading forecast data");
      await weatherData.loadData(`https://api.open-meteo.com/v1/forecast?latitude=${PERTH.lat}&longitude=${PERTH.lon}&daily=temperature_2m_max,temperature_2m_min,rain_sum&past_days=${forecastDaysRequired}&forecast_days=1&timezone=Asia%2FSingapore`);
    } else {
      console.debug("Last update", weatherData.lastUpdate, "NOT downloading forecast data");
    }

    weatherData.parseResults();

    weatherData.saveToCache();

    return weatherData;
  } catch (e) {
    console.error(e);
  }
}

const minMaxObj = {minimum: NaN, maximum: NaN};

/**
 * Map multiple arrays back to a single object by name
 * @param data
 * @returns {Promise<void>}
 */
async function parseRaw(dailyData) {
  const ret = {};

  for (let i = 0; i < dailyData.time.length; ++i) {
    const dateString = dailyData.time[i];
    const dateValue = new Date(dateString);

    ret[dateString] = {
      time: dateValue,
      [TEMP_MAX_KEY]: dailyData[TEMP_MAX_KEY][i],
      [TEMP_MIN_KEY]: dailyData[TEMP_MIN_KEY][i],
      [RAIN_SUM_KEY]: dailyData[RAIN_SUM_KEY][i],
    };
  }

  return ret;
}

function setCurrentDate(d) {
  currentDate = d;
  currentDay = formatDate(currentDate);

  currentDateLarge.innerText = format(currentDate, "MMMM d");

  sliderDate.value = getDayOfYear(d);

  [...document.getElementsByClassName("mouseover-stats")].forEach(el => el.classList.add("hidden"));
}

const dailyData = {};
let currentDate;
let currentDay;

setCurrentDate(new Date());

currentDateLarge.classList.remove("loading");

function attachEventHandlers() {
  btnPrevDay.addEventListener("click", () => {
    setCurrentDate(addDays(currentDate, -1));
    return generateCharts();
  });

  btnNextDay.addEventListener("click", () => {
    setCurrentDate(addDays(currentDate, 1));
    return generateCharts();
  });

  btnToday.addEventListener("click", () => {
    setCurrentDate(new Date());
    return generateCharts();
  });

  sliderDate.addEventListener("input", (evt) => {
    setCurrentDate(setDayOfYear(new Date(), evt.target.value));
    return generateCharts();
  });
}

let weatherData = null;

async function handleLoad() {
  attachEventHandlers();

  weatherData = await fetchData();

  if (!weatherData) {
    console.error("Something went wrong!");
    return;
  }

  totalRangeStart.classList.remove("loading");
  totalRangeEnd.classList.remove("loading");
  totalRangeStart.innerText = format(weatherData.ranges.time.minimum, "MMMM d, yyyy");
  totalRangeEnd.innerText = format(weatherData.ranges.time.maximum, "MMMM d, yyyy");

  return generateCharts();
}

async function generateCharts() {
  return Promise.all([
    generateChart(weatherData, TEMP_MAX_KEY, document.getElementById("containerMax"), getTemperatureRange, temperatureGradient, temperatureColor, "°"),
    generateChart(weatherData, TEMP_MIN_KEY, document.getElementById("containerMin"), getTemperatureRange, temperatureGradient, temperatureColor, "°"),
    generateChart(weatherData, RAIN_SUM_KEY, document.getElementById("containerRain"), getRainfallRange, rainfallGradient, rainfallColor, "mm"),
    // generateRainfallChart(weatherData.results[currentDay], weatherData.ranges, RAIN_SUM_KEY, document.getElementById("containerRain")),
  ]);
}

const width = 1024;
const height = 256;
const marginBottom = 30;
const marginLeft = 0;

const TEMP_MAX_KEY = "temperature_2m_max";
const TEMP_MIN_KEY = "temperature_2m_min";
const RAIN_SUM_KEY = "rain_sum";
const TIME_KEY = "time";

const ALL_KEYS = [
  TEMP_MIN_KEY,
  TEMP_MAX_KEY,
  RAIN_SUM_KEY,
  TIME_KEY,
];

const TEMPERATURE_KEYS = [
  TEMP_MIN_KEY,
  TEMP_MAX_KEY,
];

function getMinTemperature(ranges) {
  return TEMPERATURE_KEYS.reduce((prev, key) => Math.min(prev, ranges[key].minimum), Infinity);
}

function getMaxTemperature(ranges) {
  return TEMPERATURE_KEYS.reduce((prev, key) => Math.max(prev, ranges[key].maximum), -Infinity);
}

function handleTouchDrag(e, mouseover) {
  const {clientX, clientY} = e.touches[0];
  const targetElement = document.elementFromPoint(clientX, clientY);

  if (!targetElement) {
    return;
  }

  handleDrag(targetElement, mouseover);
}

function handleMouseDrag(e, mouseover) {
  handleDrag(e.target, mouseover);
}

function handleDrag(targetElement, mouseover) {
  const parentElement = mouseover.parentElement;
  const data = targetElement.dataset;

  if (data.date && data.value) {
    // console.log(e.touches[0]);
    const parentBounds = parentElement.getBoundingClientRect();
    const targetBounds = targetElement.getBoundingClientRect();
    const mouseoverBounds = mouseover.getBoundingClientRect();
    const offsetX = Math.max(0, Math.min(parentBounds.width - mouseoverBounds.width, (targetBounds.left - parentBounds.left) - mouseoverBounds.width / 2));// + (targetBounds.width / 2); // - parentBounds.x;

    mouseover.classList.remove("hidden");
    mouseover.style.left = `${offsetX}px`;
    mouseover.innerText = `${format(data.date, "yyyy")}: ${data.value}`;
  }
}

function getTemperatureRange(ranges) {
  const ltMaximum = getMaxTemperature(ranges);
  const ltMinimum = getMinTemperature(ranges);
  return [ltMinimum, ltMaximum];
}

function getRainfallRange(ranges) {
  return [ranges[RAIN_SUM_KEY].minimum, ranges[RAIN_SUM_KEY].maximum];
}

function temperatureColor(el, colorFn) {
  return colorFn(el[0]);
}

function rainfallColor(el, colorFn) {
  return el[0] <= 0.001 ? "#555555" : colorFn(el[0]);
}

async function generateChart(weatherData, dataKey, target, minMaxFunc, gradient, fillHandler, suffix) {
  const dailyData = weatherData.results[currentDay];
  const ranges = weatherData.ranges;

  const numResults = dailyData.time.length;
  const minDate = new Date(dailyData.time[0]);
  const maxDate = new Date(dailyData.time[numResults - 1]);
  const [stats, graph, mouseover] = [...target.children];

  // Create the SVG container.
  const svg = d3.create("svg")
    .on("touchmove", (e) => handleTouchDrag(e, mouseover))
    .on("mousemove", (e) => handleMouseDrag(e, mouseover))
    .attr("viewBox", `0 0 ${width} ${height}`)
    .attr("preserveAspectRatio", "xMinYMin meet");

  const [ltMinimum, ltMaximum] = minMaxFunc(ranges);

  const color = d3.scaleSequential([ltMaximum, ltMinimum], gradient);

  // Special case for Feb 29
  const rangePadding = maxDate.getMonth() === 1 && maxDate.getDate() === 29 ? 4 : 1;

  const x = d3.scaleUtc([minDate, addYears(maxDate, rangePadding)], [marginLeft, width]);

  svg.append("g")
    .attr("transform", `translate(0,${height - marginBottom})`)
    .call(d3.axisBottom(x));

  graph.replaceChildren();
  const zipped = d3.zip(dailyData[dataKey], dailyData["time"]);

  const barWidth = Math.floor(width / numResults) + 1;

  // Add a rect for each bar.
  svg.append("g")
    .selectAll()
    .data(zipped)
    .join("rect")
    .attr("fill", d => fillHandler(d, color)) //d => color(d[0]))
    .attr("x", d => x(d[1]))
    .attr("y", 0)
    .attr("height", height - marginBottom)
    .attr("width", barWidth)
    .attr("data-value", d => `${d[0]}${suffix}`)
    .attr("data-date", d => d[1])
  ;

  // Append the SVG element.
  graph.append(svg.node());
  stats.innerText = `Highest: ${dailyData.ranges[dataKey].maximum}${suffix}, lowest: ${dailyData.ranges[dataKey].minimum}${suffix}`;
}

async function generateRainfallChart(dailyData, ranges, dataKey, target) {
  const numResults = dailyData.time.length;
  const minDate = new Date(dailyData.time[0]);
  const maxDate = new Date(dailyData.time[numResults - 1]);

  // Create the SVG container.
  const svg = d3.create("svg")
    .on("touchmove", (e) => handleTouchDrag(e, mouseover))
    .on("mousemove", (e) => handleMouseDrag(e, mouseover))
    .attr("viewBox", `0 0 ${width} ${height}`)
    .attr("preserveAspectRatio", "xMinYMin meet");

  const ltMaximum = ranges[dataKey].maximum;
  const ltMinimum = ranges[dataKey].minimum;

  const color = d3.scaleSequential([ltMaximum, ltMinimum], rainfallGradient);

  const rangePadding = maxDate.getMonth() === 1 && maxDate.getDate() === 29 ? 4 : 1;

  // BUG: addYears should add 4 years if this is a leap year
  const x = d3.scaleUtc([minDate, addYears(maxDate, rangePadding)], [marginLeft, width]);

  svg.append("g")
    .attr("transform", `translate(0,${height - marginBottom})`)
    .call(d3.axisBottom(x));

  const [stats, graph, mouseover] = [...target.children];
  graph.replaceChildren();
  const zipped = d3.zip(dailyData[dataKey], dailyData["time"]);

  // console.debug(zipped);
  const barWidth = Math.floor(width / numResults) + 1;

  // Add a rect for each bar.
  svg.append("g")
    .selectAll()
    .data(zipped)
    .join("rect")
    .attr("fill", d => d[0] <= 0.001 ? "#555555" : color(d[0]))
    .attr("x", d => x(d[1]))
    .attr("y", 0)
    .attr("height", height - marginBottom)
    .attr("width", barWidth)
    .attr("data-value", d => d[0])
    .attr("data-date", d => d[1])
  ;

  // Append the SVG element.
  graph.append(svg.node());
  stats.innerText = `Highest: ${dailyData.ranges[dataKey].maximum}mm, lowest: ${dailyData.ranges[dataKey].minimum}mm`;
}

addEventListener("DOMContentLoaded", handleLoad);
